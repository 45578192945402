.renovation  {
    background-color: #a30000;
    color: white;
    display: flex;
    font-family: ShareTechMono;
    font-size: 14px;
    height: 24px;
    width: 100%;
    justify-content: center;
    line-height: 24px;
    max-width: 100vw;
    overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 1;
}
