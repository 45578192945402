// $breakpoint-tablet: 768px;

.app {
    height: 100%;
    min-width: 570px;
    width: 100%;
    // app standard button
    button {
        border: none;
        &:focus {
            outline: none;
        }
    }
}


// TODO: make everything mobile responsive and conditionally render
// @media (max-width: $breakpoint-tablet) {
//     html, body {
//         max-width: 100vw;
//         overflow-x: hidden;
//         .app {
//             .intro {
//                 flex-direction: column;
//                 .half {
//                     height: 50%;
//                     width: 100%;
//                 }
//             }
//         }
//     }
// }
