.intro {
    background-image: url('../../assets/skyline.svg');
    background-position-x: 15%;
    background-position-y: 87.5%;
    background-repeat: no-repeat;
    background-size: 115%;
    display: flex;
    height: 100vh;
    width: 100vw;
    .half {
        display: flex;
        align-items: center;
        height: 100%;
        width: 50%;
        min-width: 285px;
        padding: 0 5%;
        .title-writer-wrapper {
            padding: 0 0 20% 10%;
            .title-writer {
                font-family: ShareTechMono;
                font-size: 48px;
            }
        }
        .profile-picture {
            border-radius: 50%;
            float: center;
            padding-bottom: 20%;
            width: 80%;
        }
    }
}
