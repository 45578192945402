.navbar {
    background-color: white;
    display: flex;
    height: 24px;
    width: 100%;
    max-width: 100vw;
    justify-content: space-between;
    overflow: hidden;
    position: fixed;
    top: 0;
    z-index: 1;
    button {
        background-color: #333;
        color: white;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-family: ShareTechMono;
        font-size: 14px;
        justify-content: center;
        text-decoration: none;
        transition: background-color .25s ease-in-out;
        -moz-transition: background-color .25s ease-in-out;
        -webkit-transition: background-color .25s ease-in-out;
        width: calc(25% - 1px);
        &.active, &:hover {
            background-color: white;
            color: #333;
        }
    }
}
