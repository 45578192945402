.project-card {
    background-color: white;
    border: 1px solid lightgrey;
    border-radius: 25px;
    box-shadow: 2.5px 2.5px 2.5px lightgrey;
    height: 20vw;
    width: 20vw;
    margin: 20px 0;
    overflow: hidden;
    transition: box-shadow .1s ease-in-out;
    -moz-transition: box-shadow .1s ease-in-out;
    -webkit-transition: box-shadow .1s ease-in-out;
    &:hover {
        box-shadow: 5px 5px 5px lightgrey;
    }
    .photo {
        border-bottom: 1px solid lightgrey;
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: 150% 10%;
    }
}
