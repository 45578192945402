.about {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 24px);
    width: 100vw;
    .description {
        font-family: ShareTechMono;
        font-size: 18px;
        width: 45%;
        button {
            background-color: white;
            border: 1px solid #333;
            cursor: pointer;
            font-family: ShareTechMono;
            font-size: 18px;
            margin-top: 10px;
            padding: 5px 30px;
            text-decoration: none;
            &:hover {
                background-color: #333;
                color: white;
            }
        }
    }
    .reading-bitmoji {
        height: 40%;
        margin: 50px;
    }
    .waterloo-logo {
        height: 30vw;
        left: -15vw;
        opacity: 0.1;
        position: absolute;
    }
    .laurier-logo {
        height: 30vw;
        right: -15vw;
        opacity: 0.1;
        position: absolute;
    }
}
